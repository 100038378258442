<script>
  // store modules
  import logsModule from "@/config/store/logs/user_action"
  import { mapGetters } from "vuex"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import logsCommonMixin from "@/pages/Logs/logsCommonMixin"

  // misc
  import { columns } from "./TableConfig"
  import { buildUserActionLogTypes } from "@/helpers/logs"

  const storeUsageMixin = withStoreModule(logsModule, {
    resetState: true,
    name: "userActionLogs",
    readers: {
      logs: "items",
      filters: "filters",
      logsLoading: "loading",
      pagination: "pagination"
    },
    actions: { fetchLogs: "FETCH_POST_ITEMS" },
    mutations: {
      applyFilters: "SET_FILTERS",
      setPagination: "SET_PAGINATION_DATA"
    }
  })

  export default {
    mixins: [logsCommonMixin, storeUsageMixin],

    computed: {
      ...mapGetters(["isCompanySystemAvailable", "isNicolasEnabled"]),

      initiators() {
        return this.extractInitiators({ add_auto_set_inventory: false, add_sale_stop_settings: false })
      },

      types() {
        return buildUserActionLogTypes(
          this.isSharedInventoryEnabled,
          this.isNicolasEnabled,
          this.isCompanySystemAvailable
        )
      }
    },

    data() {
      return {
        rows: [],
        columns,
        tableWrapperClass: "user-action-logs"
      }
    }
  }
</script>
